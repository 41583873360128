import { all } from "redux-saga/effects";
import { sagas as vacationRentalShop } from "../modules/shop";
import { sagas as vacationRentalRewards } from "../modules/rewards";
import { sagas as vacationRentalBook } from "../modules/book";
import { sagas as vacationRentalTravelWallet } from "../modules/travel-wallet";

export function* rootSaga() {
  yield all([
    vacationRentalRewards.watchFetchRewardsAccounts(),
    vacationRentalShop.watchFetchVacationRentalShopSaga(),

    vacationRentalBook.watchFetchUserPassengers(),
    vacationRentalBook.watchDeletePassengerSaga(),
    vacationRentalBook.watchUpdateUserPassenger(),

    vacationRentalBook.watchFetchPaymentMethodSaga(),
    vacationRentalBook.watchDeletePaymentMethodSaga(),
    vacationRentalBook.watchListPaymentMethodsSaga(),
    vacationRentalBook.watchVerifyPaymentMethodSaga(),

    vacationRentalBook.watchProductToEarnSaga(),
    vacationRentalBook.watchFetchAllEarnForProductSaga(),
    vacationRentalBook.watchFetchApplicableTravelWalletItemsSaga(),

    vacationRentalBook.watchScheduleVacationRentalPriceQuoteSaga(),
    vacationRentalBook.watchPollVacationRentalsPriceQuoteSaga(),
    vacationRentalBook.watchAddMainGuestSaga(),
    vacationRentalBook.watchScheduleVacationRentalsBookSaga(),
    vacationRentalBook.watchPollVacationRentalsConfirmationDetailsSaga(),

    vacationRentalTravelWallet.watchFetchTravelWalletDetails(),
    vacationRentalTravelWallet.watchFetchTravelWalletCreditHistorySaga(),
  ]);
}
