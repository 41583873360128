import { actions, actionTypes } from "../actions";
import { RewardsAccount } from "redmond";

export interface IVacationRentalRewardsState {
  rewardsAccounts: RewardsAccount[];
  selectedAccountReferenceId: string | null;
  fetchRewardsAccountsCallState: CallState;
  isFirstLaunch: boolean;
  agentEmail: string;
  isAutoApprovalEnabled: boolean;
}

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

const initialState: IVacationRentalRewardsState = {
  rewardsAccounts: [],
  selectedAccountReferenceId: null,
  fetchRewardsAccountsCallState: CallState.NotCalled,
  isFirstLaunch: false,
  agentEmail: "",
  isAutoApprovalEnabled: false,
};

export function reducer(
  state: IVacationRentalRewardsState = initialState,
  action: actions.VacationRentalRewardsActions
): IVacationRentalRewardsState {
  switch (action.type) {
    case actionTypes.SET_IS_FIRST_LAUNCH:
      return {
        ...state,
        isFirstLaunch: action.isFirstLaunch,
      };

    case actionTypes.SET_AGENT_EMAIL:
      return {
        ...state,
        agentEmail: action.agentEmail,
      };

    case actionTypes.FETCH_REWARDS_ACCOUNTS:
      return {
        ...state,
        fetchRewardsAccountsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchRewardsAccountsCallState: CallState.Failed,
      };

    case actionTypes.SET_REWARDS_ACCOUNTS:
      return {
        ...state,
        rewardsAccounts: action.rewardsAccounts.map((account) => ({
          ...account,
          earn: {
            ...account.earn,
            // For general vacation rentals we are currently using the hotels multiplier
            // for the earn value. The homesMultiplier is currently configured for luxury
            // vacation rentals.
            homesMultiplier: account.earn.hotelsMultiplier,
          },
        })),
        fetchRewardsAccountsCallState: CallState.Success,
      };

    case actionTypes.SET_SELECTED_ACCOUNT_REFERENCE_ID:
      return {
        ...state,
        selectedAccountReferenceId: action.selectedAccountReferenceId,
      };

    case actionTypes.SET_AUTO_APPROVAL:
      return {
        ...state,
        isAutoApprovalEnabled: action.isAutoApprovalEnabled,
      };

    default:
      return state;
  }
}

export * from "./selectors";
