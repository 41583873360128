import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  ActionButton,
  MobileFloatingButton,
} from "halifax";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../search/components/VacationRentalSearchControl/components/MonthAndDatePicker";
import { RouteComponentProps } from "react-router";
import { MobileDateSelectorConnectorProps } from "./container";
import dayjs from "dayjs";
import "./styles.scss";

export enum IMobileDateSelectorVariant {
  ICON = "ICON",
  BUTTON = "BUTTON",
}

interface IMobileDateSelectorProps
  extends MobileDateSelectorConnectorProps,
    RouteComponentProps {
  variant: IMobileDateSelectorVariant;
  unavailableReasonText?: string;
}

export const MobileDateSelector = (props: IMobileDateSelectorProps) => {
  const {
    fromDate,
    untilDate,
    variant,
    unavailableReasonText = "This listing is unavailable.",
    setFromDate,
    setUntilDate,
    fetchVacationRentalShop,
    history,
  } = props;

  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);
  const [hasChangedDates, setHasChangedDates] = useState(false);
  const [from, setFrom] = useState<Date | null>(fromDate);
  const [until, setUntil] = useState<Date | null>(untilDate);

  useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  useEffect(() => {
    const differentDates = from !== fromDate || until !== untilDate;
    const setBothDates = fromDate && untilDate;

    if (differentDates && setBothDates) {
      setHasChangedDates(true);
    } else {
      setHasChangedDates(false);
    }
  }, [fromDate, untilDate, from, until]);

  const openModal = () => {
    setIsDatesModalOpen(true);
  };

  const closeModal = () => {
    setIsDatesModalOpen(false);
  };

  const handleChangeDates = () => {
    setFromDate(from);
    setUntilDate(until);
    closeModal();
    fetchVacationRentalShop(history, {
      fetchListingCalendar: true,
    });
  };

  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography>{value || label}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {variant === IMobileDateSelectorVariant.ICON && (
        <ActionLink
          onClick={openModal}
          content={<Icon name={IconName.B2BEditPencil} className="edit-icon" />}
        />
      )}

      {variant === IMobileDateSelectorVariant.BUTTON && (
        <Box
          className="mobile-date-selector"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ backgroundColor: "white" }}
          paddingTop="20px"
        >
          <Typography style={{ fontSize: "14px", fontWeight: "600" }}>
            {unavailableReasonText}
          </Typography>
          <ActionButton
            className="action-button-outer-override"
            buttonClassName="action-button-inner-override"
            onClick={openModal}
            message={
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "white",
                }}
              >
                Change dates
              </Typography>
            }
          />
        </Box>
      )}

      <MobilePopoverCard
        open={isDatesModalOpen}
        onClose={() => setIsDatesModalOpen(false)}
        className="mobile-pc-shop-header-root"
        contentClassName="mobile-shop-header-content-wrapper"
        headerElement={
          <Box className="modal-header-container">
            <Typography className="header-title">Choose Dates</Typography>
            <ActionLink
              className="modal-close-button"
              onClick={() => setIsDatesModalOpen(false)}
              content={<CloseButtonIcon className="close-button-icon" />}
              label="Close"
              showTappableArea
            />
          </Box>
        }
      >
        <Box className="mobile-shop-header-content-container">
          <Box className="pc-mobile-calendar-picker-root">
            <MonthAndDatePicker
              viewType={MonthAndDatePickerType.Column}
              from={from}
              until={until}
              setFromDate={setFrom}
              setUntilDate={setUntil}
              className="b2b"
              header={
                <Box className="premier-collection-mobile-calendar-header">
                  <DatePickerHeader
                    label="Check-in"
                    value={from ? dayjs(from).format("ddd D, MMM") : undefined}
                  />
                  <DatePickerHeader
                    label="Checkout"
                    value={
                      until ? dayjs(until).format("ddd D, MMM") : undefined
                    }
                  />
                </Box>
              }
            />

            {hasChangedDates && (
              <MobileFloatingButton
                className="mobile-calendar-picker-search-button"
                disabled={!(!!from && !!until)}
                onClick={handleChangeDates}
                wrapperClassName="b2b"
              >
                Search Dates
              </MobileFloatingButton>
            )}
          </Box>
        </Box>
      </MobilePopoverCard>
    </>
  );
};
