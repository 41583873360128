import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { PolicyEnum, Prices } from "redmond";
import { VacationRentalShopConnectorProps } from "./container";
import {
  ActionButton,
  getPricesWithComma,
  getTotalPriceText,
  Icon,
  IconName,
  pluralize,
} from "halifax";
import "./styles.scss";
import { ConnectedDateSelector } from "../DateSelector/container";
import { goToCheckout } from "../../utils/queryStringHelpers";
import { ConnectedOccupancySelector } from "../OccupancySelector/container";
import { IDateSelectorVariant } from "../DateSelector/component";
import { IMobileDateSelectorVariant } from "../MobileDateSelector/component";
import { ConnectedMobileDateSelector } from "../MobileDateSelector/container";
import {
  AvailabilityResultEnum,
  FundingEnum,
  Price,
} from "redmond/apis/tysons/vacation-rentals";
import { VacationRentalShopCallState } from "../../reducer";
import { Skeleton } from "@material-ui/lab";

// Display context of the ShopCta
export enum IShopCtaVariant {
  DESKTOP = "DESKTOP",
  DESKTOP_MAP = "DESKTOP_MAP",
  CTA_BUTTON_ONLY = "BUTTON_ONLY",
  PRICING_ONLY = "PRICING_ONLY",
}

export interface IShopCtaProps
  extends VacationRentalShopConnectorProps,
    RouteComponentProps {
  variant: IShopCtaVariant;
}

export const ShopCta = (props: IShopCtaProps) => {
  const {
    variant,
    selectedListing,
    selectedRewardsAccount,
    lengthOfStay,
    history,
    shopCallState,
  } = props;

  if (!selectedListing) {
    return null;
  }

  const { availability, listing } = selectedListing;
  const maxOccupancy = listing.terms?.maxOccupancy;

  if (availability.AvailabilityResult === AvailabilityResultEnum.Unavailable) {
    const failureReason = availability?.reasons?.[0]?.reason?.Reason;

    let unavailableReasonText = "";

    switch (failureReason) {
      case "MinStayViolation":
        unavailableReasonText =
          "Your requested stay is shorter than the minimum required.";
        break;
      case "MaxStayViolation":
        unavailableReasonText =
          "Your requested stay is longer than the maximum allowed duration.";
        break;
      case "OccupancyViolation":
        unavailableReasonText =
          "You have exceeded the maximum occupancy limit.";
        break;
      case "PetPolicyViolation":
        unavailableReasonText = "This listing does not allow pets.";
        break;
      case "CheckInNotAllowed":
        unavailableReasonText = "Your selected check in day is not allowed.";
        break;
      case "CheckOutNotAllowed":
        unavailableReasonText = "Your selected check-out day is not allowed.";
        break;
      case "BlockedDates":
        unavailableReasonText =
          "The dates you have selected are not available.";
        break;
      case "MinBookingWindowViolation":
        unavailableReasonText =
          "This listing can't be booked with such short notice.";
        break;
      default:
        unavailableReasonText = "This listing is unavailable.";
    }

    return (
      <Box className="vacation-rental-shop-cta">
        {variant === IShopCtaVariant.DESKTOP && (
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              padding="20px"
              style={{ gap: "20px" }}
            >
              <Typography>{unavailableReasonText}</Typography>
              <Box
                display="flex"
                flexDirection="column"
                style={{ gap: "12px" }}
              >
                <ConnectedDateSelector variant={IDateSelectorVariant.RANGE} />
                <ConnectedOccupancySelector maxOccupancy={maxOccupancy} />
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              flexDirection="column"
              padding="0px 20px 20px 20px"
              style={{ gap: "12px" }}
            >
              <ConnectedDateSelector variant={IDateSelectorVariant.BUTTON} />
            </Box>
          </Box>
        )}

        {variant === IShopCtaVariant.CTA_BUTTON_ONLY && (
          <Box className="cta-button-mobile">
            <ConnectedMobileDateSelector
              variant={IMobileDateSelectorVariant.BUTTON}
              unavailableReasonText={unavailableReasonText}
            />
          </Box>
        )}
      </Box>
    );
  }

  const { rate } = availability;

  const { price, cancellationPolicy } = rate || {};

  const isSkeleton = shopCallState === VacationRentalShopCallState.InProcess;

  const { nightly, total, rentTotal, taxes, fees, discounts } = price as Price;

  const formatAmountWithCurrency = (
    amount: number,
    currencySymbol: string = ""
  ) => {
    return formatPrice({
      fiat: {
        value: amount,
        currencySymbol,
        currencyCode: "",
      },
      rewards: {},
    });
  };

  const vacationRentalPriceFormatter = (price: number): string =>
    Math.round(price).toString();

  const formatPrice = (price: Prices) => {
    return getPricesWithComma(
      getTotalPriceText({
        price: price?.fiat,
        priceFormatter: vacationRentalPriceFormatter,
      })
    );
  };

  const formatRewardsPrice = (price: Prices) => {
    const rewardsPrice = selectedRewardsAccount
      ? price.rewards[selectedRewardsAccount]
      : null;

    if (!rewardsPrice) {
      return "";
    } else {
      let miles = rewardsPrice?.value;
      let milesWithComma = miles.toLocaleString("en-US"); // Add comma to the miles amount. ie: 30000 -> 30,000
      return `/ ${milesWithComma} ${rewardsPrice?.currency.toLocaleLowerCase(
        "en-US"
      )}`;
    }
  };

  const taxesTotal = taxes
    .map((x) => x.amount?.fiat?.value)
    .reduce((partialSum, a) => partialSum + a, 0);

  const feesTotal = fees
    .map((x) => x.amount?.fiat?.value)
    .reduce((partialSum, a) => partialSum + a, 0);

  const taxesAndFeesTotal = taxesTotal + feesTotal;

  const discountsTotal =
    discounts
      ?.map((discount) => {
        const hopperFunded =
          discount.funding.Funding === FundingEnum.HopperFunded ||
          discount.funding.Funding === FundingEnum.JointFunded
            ? +discount.funding.hopperFunded.amount.fiat.value || 0
            : 0;
        const hostFunded =
          discount.funding.Funding === FundingEnum.HostFunded ||
          discount.funding.Funding === FundingEnum.JointFunded
            ? +discount.funding.hostFunded.amount.fiat.value || 0
            : 0;
        return hopperFunded + hostFunded;
      })
      .reduce((partialSum, a) => partialSum + a, 0) || 0;

  const nightlyPriceDisplay = formatPrice(nightly);
  const nightlyPriceBeforeDiscountsDisplay =
    discountsTotal > 0 && !!lengthOfStay
      ? formatPrice({
          fiat: {
            value: nightly.fiat.value + discountsTotal / lengthOfStay,
            currencySymbol: nightly.fiat.currencySymbol,
            currencyCode: nightly.fiat.currencyCode,
          },
          rewards: {},
        })
      : null;
  const rentPriceDisplay = formatPrice(rentTotal);
  const totalPriceDisplay = formatPrice(total);
  const milesPriceDisplay = formatRewardsPrice(total);
  const totalTaxesAndFeesDisplay = formatAmountWithCurrency(
    taxesAndFeesTotal,
    total.fiat?.currencySymbol
  );

  const renderPricingDetails = () => {
    if (isSkeleton) {
      return (
        <Box>
          <Skeleton width="150px" height="20px" />
          <Skeleton width="100px" height="20px" />
        </Box>
      );
    }

    return (
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="baseline"
          style={{ gap: "4px" }}
        >
          {!!nightlyPriceBeforeDiscountsDisplay && (
            <Typography
              style={{
                fontSize: "12px",
                color: "#CC2427",
                textDecoration: "line-through",
              }}
            >
              {nightlyPriceBeforeDiscountsDisplay}
            </Typography>
          )}
          <Typography
            style={{ fontWeight: 600, fontSize: "16px", color: "#141414" }}
          >
            {nightlyPriceDisplay}
            <span style={{ fontSize: "12px" }}> per night</span>
          </Typography>
        </Box>
        {variant === IShopCtaVariant.PRICING_ONLY && (
          <>
            {!!lengthOfStay && (
              <Typography style={{ fontSize: "12px", color: "#141414" }}>
                {totalPriceDisplay} for {lengthOfStay}{" "}
                {pluralize(lengthOfStay, "night", "nights")} {milesPriceDisplay}
              </Typography>
            )}
            <Typography
              style={{
                fontSize: "12px",
                color: "#676868",
                marginTop: "10px",
              }}
            >
              Total includes taxes and fees.
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const renderFreeCancellation = () => {
    if (isSkeleton) {
      return (
        <Box>
          <Skeleton width="100px" height="20px" />
        </Box>
      );
    }

    return (
      cancellationPolicy?.policy.Policy === PolicyEnum.FullyRefundable && (
        <Box display="flex" justifyContent="center" paddingBottom="10px">
          <Icon name={IconName.FreeCancel} />
          <Typography
            style={{
              fontSize: "12px",
              fontWeight: "600",
              color: "var(--green-5)",
              marginLeft: "5px",
            }}
          >
            Free cancellation
          </Typography>
        </Box>
      )
    );
  };

  return (
    <Box className="vacation-rental-shop-cta">
      {variant === IShopCtaVariant.DESKTOP && (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            padding="20px"
            style={{ gap: "12px" }}
            alignItems="start"
          >
            {renderPricingDetails()}
            {renderFreeCancellation()}
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: "12px", width: "100%" }}
            >
              <ConnectedDateSelector variant={IDateSelectorVariant.RANGE} />
              <ConnectedOccupancySelector maxOccupancy={maxOccupancy} />
            </Box>
          </Box>
          {!isSkeleton && (
            <>
              <Divider />
              <Box
                display="flex"
                flexDirection="column"
                padding="20px"
                style={{ gap: "12px" }}
              >
                <Typography variant="h6" style={{ color: "#141414" }}>
                  Checkout breakdown
                </Typography>
                <Divider style={{ height: "0.5px" }} />
                <Box display="flex" justifyContent="space-between">
                  {lengthOfStay && (
                    <Typography style={{ fontSize: "14px", color: "#676868" }}>
                      Vacation rental x {lengthOfStay}{" "}
                      {pluralize(lengthOfStay, "night", "nights")}
                    </Typography>
                  )}
                  <Typography style={{ fontSize: "14px", color: "#676868" }}>
                    {rentPriceDisplay}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography style={{ fontSize: "14px", color: "#676868" }}>
                    Taxes and fees
                  </Typography>
                  <Typography style={{ fontSize: "14px", color: "#676868" }}>
                    {totalTaxesAndFeesDisplay}
                  </Typography>
                </Box>
                <Divider style={{ height: "0.5px" }} />
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6" style={{ color: "#141414" }}>
                    Total
                  </Typography>

                  <Typography variant="h6" style={{ color: "#141414" }}>
                    {totalPriceDisplay}{" "}
                    <span style={{ fontWeight: 400, color: "#676868" }}>
                      {milesPriceDisplay}
                    </span>
                  </Typography>
                </Box>
                <ActionButton
                  className="cta-button-outer"
                  buttonClassName="cta-button-inner"
                  onClick={() => goToCheckout({ history })}
                  message={
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "white",
                      }}
                    >
                      Continue for{" "}
                      <span style={{ fontWeight: "600" }}>
                        {nightlyPriceDisplay} per night
                      </span>
                    </Typography>
                  }
                />
              </Box>
            </>
          )}
        </Box>
      )}
      {variant === IShopCtaVariant.CTA_BUTTON_ONLY && (
        <Box
          paddingLeft="15px"
          paddingRight="15px"
          paddingBottom="25px"
          style={{ backgroundColor: "white" }}
        >
          <ActionButton
            className="cta-button-outer"
            buttonClassName="cta-button-inner"
            onClick={() => goToCheckout({ history })}
            message={
              <Typography
                style={{
                  fontSize: "16px",
                  color: "white",
                }}
              >
                Continue for{" "}
                <span style={{ fontWeight: "600" }}>
                  {nightlyPriceDisplay} per night
                </span>
              </Typography>
            }
          />
        </Box>
      )}
      {variant === IShopCtaVariant.PRICING_ONLY && renderPricingDetails()}
    </Box>
  );
};
