import React from "react";
import { AmenityIcon, Icon, IconName } from "halifax";
import { VacationRentalAmenityKindEnum } from "redmond";
import { AmenityKindEnum } from "redmond/apis/tysons/vacation-rentals";

export const MOST_POPULAR_AMENITIES: AmenityKindEnum[] = [
  AmenityKindEnum.Kitchen,
  AmenityKindEnum.AirConditioning,
  AmenityKindEnum.Wifi,
  AmenityKindEnum.Washer,
  AmenityKindEnum.CoffeeMaker,
  // AmenityKindEnum.Dishwasher,
  AmenityKindEnum.Parking,
  AmenityKindEnum.Dryer,
  AmenityKindEnum.Pool,
  AmenityKindEnum.OutdoorSpace,
  AmenityKindEnum.BarbecueGrill,
  AmenityKindEnum.HotTub,
  AmenityKindEnum.Gym,
  AmenityKindEnum.Accessibility,
  AmenityKindEnum.PetFriendly,
  AmenityKindEnum.FreeParking,
  // AmenityKindEnum.FirePit,
  // AmenityKindEnum.Sauna,
];

export enum AmenityCategoryEnum {
  General = "General",
  Bathroom = "Bathroom",
  Bedroom = "Bedroom",
  TechMedia = "Tech and Media",
  Pets = "Pets",
  Parking = "Parking",
  SafetySecurity = "Safety & Security ",
  Cleaning = "Cleaning",
  Kitchen = "Kitchen",
  Outdoor = "Outdoor",
}

export const AmenityCategoryOrder = Object.values(AmenityCategoryEnum);

export const AmenityCategoryIconMap = {
  [AmenityCategoryEnum.General]: (
    <Icon name={IconName.InfoCircle} className="icon-general" />
  ),
  [AmenityCategoryEnum.Pets]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.PetFriendly} />
  ),
  [AmenityCategoryEnum.Bathroom]: (
    <Icon name={IconName.BathTubOutlineIcon} className="icon-bathroom" />
  ),
  [AmenityCategoryEnum.Parking]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.Parking} />
  ),
  [AmenityCategoryEnum.Bedroom]: (
    <Icon name={IconName.BedOutlineIcon} className="icon-bedroom" />
  ),
  [AmenityCategoryEnum.SafetySecurity]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.SecuritySystem} />
  ),
  [AmenityCategoryEnum.TechMedia]: (
    <Icon name={IconName.BedOutlineIcon} className="icon-bedroom" />
  ),
  [AmenityCategoryEnum.Cleaning]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.Washer} />
  ),
  [AmenityCategoryEnum.Kitchen]: (
    <AmenityIcon amenity={VacationRentalAmenityKindEnum.Kitchen} />
  ),
  [AmenityCategoryEnum.Outdoor]: (
    <div className="icon-fill-black">
      <AmenityIcon amenity={VacationRentalAmenityKindEnum.OutdoorSpace} />
    </div>
  ),
} as any;

export const amenityKindToCategoryMap = {
  [AmenityKindEnum.Accessibility]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.AccessibleBathroom]: AmenityCategoryEnum.Bathroom,
  [AmenityKindEnum.AccessibleFacilities]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.AirConditioning]: AmenityCategoryEnum.General,
  [AmenityKindEnum.AllergyFriendly]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.BarbecueGrill]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.BarInBuilding]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Bathtub]: AmenityCategoryEnum.Bathroom,
  [AmenityKindEnum.BeachFront]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.BeachView]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.BedLinens]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.Bicycle]: AmenityCategoryEnum.General,
  [AmenityKindEnum.BoardGames]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Cable]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.CarbonMonoxideAlarm]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.CeilingFan]: AmenityCategoryEnum.General,
  [AmenityKindEnum.CityView]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.CleaningProducts]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.Coffee]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.CoffeeMaker]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.CoffeeTea]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.ConvenientCheckInOut]: AmenityCategoryEnum.General,
  [AmenityKindEnum.CookingBasics]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.Crib]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.DedicatedWorkspace]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.DiningTable]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.DishesAndSilverware]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.Dishwasher]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.DoormanEntry]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.Dryer]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.Elevator]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Entertainment]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.Essentials]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.EvCharger]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.ExtraPillowsAndBlankets]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.FireExtinguisher]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.FirePit]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.Fireplace]: AmenityCategoryEnum.General,
  [AmenityKindEnum.FirstAidKit]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.FitnessCenter]: AmenityCategoryEnum.General,
  [AmenityKindEnum.FreeParking]: AmenityCategoryEnum.Parking,
  [AmenityKindEnum.Freezer]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.FrontDesk]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.GamingConsole]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.GardenOrBackyard]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.Gym]: AmenityCategoryEnum.General,
  [AmenityKindEnum.HairDryer]: AmenityCategoryEnum.Bathroom,
  [AmenityKindEnum.Hangers]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.Hdtv]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.Heating]: AmenityCategoryEnum.General,
  [AmenityKindEnum.HighChair]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.HotTub]: AmenityCategoryEnum.General,
  [AmenityKindEnum.HotWater]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.HotWaterKettle]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.Housekeeping]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.InternetAccess]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.Iron]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.Kayak]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.KidFriendly]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.Kitchen]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.Kitchenette]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.Laundry]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.MealAvailable]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Microwave]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.MountainView]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.NatureView]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.OnDemandHousekeeping]: AmenityCategoryEnum.General,
  [AmenityKindEnum.OutdoorSeating]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.OutdoorSpace]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.Oven]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.PaidParking]: AmenityCategoryEnum.Parking,
  [AmenityKindEnum.Parking]: AmenityCategoryEnum.Parking,
  [AmenityKindEnum.PatioOrBalcony]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.PetFriendly]: AmenityCategoryEnum.Pets,
  [AmenityKindEnum.Piano]: AmenityCategoryEnum.General,
  [AmenityKindEnum.PingPongTable]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Pool]: AmenityCategoryEnum.General,
  [AmenityKindEnum.PoolTable]: AmenityCategoryEnum.General,
  [AmenityKindEnum.PrivateEntrance]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.PropertyView]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.Refrigerator]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.RestaurauntInBuilding]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.Sauna]: AmenityCategoryEnum.General,
  [AmenityKindEnum.SecureEntry]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.SecureStorage]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.SecuritySystem]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.Shampoo]: AmenityCategoryEnum.Bathroom,
  [AmenityKindEnum.SkiInSkiOut]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.SmokeAlarm]: AmenityCategoryEnum.SafetySecurity,
  [AmenityKindEnum.SmokingAllowed]: AmenityCategoryEnum.General,
  [AmenityKindEnum.SoundSystem]: AmenityCategoryEnum.TechMedia,
  [AmenityKindEnum.Stove]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.StreetParking]: AmenityCategoryEnum.Parking,
  [AmenityKindEnum.Toaster]: AmenityCategoryEnum.Kitchen,
  [AmenityKindEnum.Unspecified]: AmenityCategoryEnum.General,
  [AmenityKindEnum.WardrobeOrCloset]: AmenityCategoryEnum.Bedroom,
  [AmenityKindEnum.Washer]: AmenityCategoryEnum.Cleaning,
  [AmenityKindEnum.Waterfront]: AmenityCategoryEnum.Outdoor,
  [AmenityKindEnum.WaterView]: AmenityCategoryEnum.General,
  [AmenityKindEnum.Wifi]: AmenityCategoryEnum.TechMedia,
};
