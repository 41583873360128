import { takeLeading } from "redux-saga/effects";

import { actionTypes } from "../actions";
import {
  deleteUserPassengerSaga,
  updateUserPassengerSaga,
  fetchUserPassengersSaga,
} from "./user-passenger";
import {
  deletePaymentMethodSaga,
  fetchPaymentMethodSaga,
  listPaymentMethodsSaga,
  verifyPaymentMethodSaga,
} from "./payment-method";
import {
  scheduleVacationRentalPriceQuoteSaga,
  pollVacationRentalsPriceQuoteSaga,
  scheduleVacationRentalsBookSaga,
  pollVacationRentalsConfirmationDetailsSaga,
  addMainGuestSaga,
} from "./vacation-rental-book";
import { fetchApplicableTravelWalletItemsSaga } from "./travel-wallet/fetchApplicableTravelWalletItemsSaga";
import { fetchProductToEarnSaga } from "./rewards/fetchProductToEarnSaga";
import { fetchAllEarnForProductSaga } from "./rewards/fetchAllEarnForProductSaga";

export function* watchUpdateUserPassenger() {
  yield takeLeading(actionTypes.UPDATE_USER_PASSENGER, updateUserPassengerSaga);
}

export function* watchDeletePassengerSaga() {
  yield takeLeading(actionTypes.DELETE_USER_PASSENGER, deleteUserPassengerSaga);
}

export function* watchFetchUserPassengers() {
  yield takeLeading(actionTypes.FETCH_USER_PASSENGERS, fetchUserPassengersSaga);
}

export function* watchDeletePaymentMethodSaga() {
  yield takeLeading(actionTypes.DELETE_PAYMENT_METHOD, deletePaymentMethodSaga);
}

export function* watchFetchPaymentMethodSaga() {
  yield takeLeading(actionTypes.FETCH_PAYMENT_METHOD, fetchPaymentMethodSaga);
}

export function* watchListPaymentMethodsSaga() {
  yield takeLeading(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}

export function* watchVerifyPaymentMethodSaga() {
  yield takeLeading(actionTypes.VERIFY_PAYMENT_METHOD, verifyPaymentMethodSaga);
}

export function* watchProductToEarnSaga() {
  yield takeLeading(actionTypes.FETCH_PRODUCT_TO_EARN, fetchProductToEarnSaga);
}

export function* watchFetchAllEarnForProductSaga() {
  yield takeLeading(
    actionTypes.FETCH_ALL_EARN_FOR_PRODUCT,
    fetchAllEarnForProductSaga
  );
}

export function* watchFetchApplicableTravelWalletItemsSaga() {
  yield takeLeading(
    actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS,
    fetchApplicableTravelWalletItemsSaga
  );
}

export function* watchScheduleVacationRentalPriceQuoteSaga() {
  yield takeLeading(
    actionTypes.SCHEDULE_VACATION_RENTAL_PRICE_QUOTE,
    scheduleVacationRentalPriceQuoteSaga
  );
}

export function* watchPollVacationRentalsPriceQuoteSaga() {
  yield takeLeading(
    actionTypes.POLL_VACATION_RENTALS_PRICE_QUOTE,
    pollVacationRentalsPriceQuoteSaga
  );
}

export function* watchAddMainGuestSaga() {
  yield takeLeading(actionTypes.ADD_MAIN_GUEST, addMainGuestSaga);
}

export function* watchScheduleVacationRentalsBookSaga() {
  yield takeLeading(
    actionTypes.SCHEDULE_VACATION_RENTALS_BOOK,
    scheduleVacationRentalsBookSaga
  );
}

export function* watchPollVacationRentalsConfirmationDetailsSaga() {
  yield takeLeading(
    actionTypes.POLL_VACATION_RENTALS_CONFIRMATION_DETAILS,
    pollVacationRentalsConfirmationDetailsSaga
  );
}
