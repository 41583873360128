import { all, put, select } from "redux-saga/effects";
import { productToEarn } from "../../../../api/v0/rewards/productToEarn";
import { EarnResponse, ProductEarnValues, TravelProductEnum } from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  getTotalVacationRentalsCreditCardPaymentRequiredInFiatPrice,
  getOfferToApply,
  getRewardAccountsWithPaymentAccounts,
} from "../../reducer";
import Logger from "../../../../utils/logger";
import { setAllEarnValues } from "../../actions/actions";
import { getHSPEarnOfferDescription } from "halifax";

export function* fetchAllEarnForProductSaga() {
  try {
    const state: IStoreState = yield select();
    const paymentTotal =
      getTotalVacationRentalsCreditCardPaymentRequiredInFiatPrice(state);

    const accountsWithSavedPayment =
      getRewardAccountsWithPaymentAccounts(state);

    if (!accountsWithSavedPayment.length || !paymentTotal?.value) {
      yield put(setAllEarnValues({}));
      return;
    }

    const offerToApply = getOfferToApply(state);

    const hspEarnOfferDescription = getHSPEarnOfferDescription(offerToApply);

    const responses: ProductEarnValues[] = yield all(
      accountsWithSavedPayment.map((method) => {
        if (!method) return;

        return productToEarn({
          account: method.accountReferenceId,
          travelProduct: TravelProductEnum.Hotels,
          amountUsd: paymentTotal.value,
          earnOfferDescription: hspEarnOfferDescription,
        }).then((earn) => {
          return {
            accountReferenceId: [method.accountReferenceId],
            earnForProduct: {
              ...earn,
              amount: earn.amount,
            },
          };
        });
      })
    );

    const earnByRewards: { [key: string]: EarnResponse } = responses.reduce(
      (acc, curr) => {
        acc[curr.accountReferenceId] = curr.earnForProduct;
        return acc;
      },
      {} as Record<string, EarnResponse>
    );
    yield put(setAllEarnValues(earnByRewards));
  } catch (e) {
    Logger.debug(e);
  }
}
