import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  getUserRatingQualifier,
  ReviewIconTooltip,
  ReviewScore,
} from "./helpers";
import "./styles.scss";
import { Review } from "redmond/apis/tysons/vacation-rentals";
import dayjs from "dayjs";

export interface IReviewsSectionProps {
  score: number | undefined;
  reviews: Review[];
}

export const ReviewsSection = (props: IReviewsSectionProps) => {
  const { score, reviews } = props;
  const [isExpanded, setIsExpanded] = useState<Boolean>(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ gap: "25px" }}
      className="vr-reviews"
    >
      {score && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography
            style={{ fontSize: "28px", fontWeight: 600, color: "#141414" }}
          >
            {getUserRatingQualifier(score)}
          </Typography>
          <ReviewIconTooltip />
          <ReviewScore score={score} size="lg" />
        </Box>
      )}

      {reviews.slice(0, isExpanded ? 6 : 3).map((review) => {
        const normalizedScore = review.overallScore * 100;
        const formattedSubmittedDate = dayjs(
          review.submittedDate.split("T")?.[0]
        ).format("MMM YYYY");

        return (
          <Box
            key={review.authorName}
            display="flex"
            flexDirection="column"
            style={{ gap: "12px" }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ gap: "10px" }}
            >
              <Typography style={{ fontWeight: 600, color: "#141414" }}>
                {getUserRatingQualifier(normalizedScore)}
              </Typography>
              <ReviewScore score={normalizedScore} size="sm" />
            </Box>

            <Typography style={{ fontSize: "14px", color: "#676868" }}>
              {review.comments}
            </Typography>

            <Box
              display="flex"
              style={{
                fontSize: "14px",
                color: "#676868",
                fontStyle: "italic",
              }}
            >
              {review.authorName},{" "}
              {formattedSubmittedDate ? formattedSubmittedDate : null}
            </Box>
          </Box>
        );
      })}

      {reviews.length > 3 && (
        <Typography
          variant="h6"
          style={{ color: "#013D58" }}
          className="hover-underline"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          {isExpanded ? "View less" : "View more"}
        </Typography>
      )}
    </Box>
  );
};
