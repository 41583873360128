import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PaymentCard } from "./component";
import {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchProductToEarn,
  fetchAllEarnForProduct,
} from "../../actions/actions";
import {
  getDeletePaymentMethodCallState,
  getListPaymentMethodsCallState,
  getPaymentMethods,
  getVerifyPaymentMethodCallState,
  getSelectedPaymentMethodId,
  getRewardsPaymentAccount,
  getRewardsPaymentAccountReferenceId,
  getVacationRentalsIsStackedTravelWalletPaymentOnly,
  getVacationRentalsIsTravelWalletCreditPaymentOnly,
  getVacationRentalsIsTravelWalletOfferPaymentOnly,
  getVacationRentalsIsCreditCardPaymentRequired,
  getTotalVacationRentalsCreditCardPaymentRequired,
  getVRHasError,
  getEarnValuesByRewardAcctId,
  getOfferToApply,
} from "../../reducer";
import {
  getAllowRewardsWithPolicy,
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../../../rewards/reducer";
import { fetchRewardsAccounts } from "../../../rewards/actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { getVacationRentalShopSelectedListing } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    paymentMethods: getPaymentMethods(state),
    rewardsAccounts: getRewardsAccounts(state),
    listPaymentMethodCallState: getListPaymentMethodsCallState(state),
    verifyPaymentMethodCallState: getVerifyPaymentMethodCallState(state),
    deletePaymentMethodCallState: getDeletePaymentMethodCallState(state),
    selectedPaymentMethodId: getSelectedPaymentMethodId(state),
    selectedRewardsPaymentAccount: getRewardsPaymentAccount(state),
    selectedRewardsPaymentAccountId: getRewardsPaymentAccountReferenceId(state),
    isCreditCardPaymentRequired:
      getVacationRentalsIsCreditCardPaymentRequired(state),
    totalCreditCardPaymentRequired:
      getTotalVacationRentalsCreditCardPaymentRequired(state),
    hasError: getVRHasError(state),
    isStackedTravelWalletPaymentOnly:
      getVacationRentalsIsStackedTravelWalletPaymentOnly(state),
    isTravelCreditPaymentOnly:
      getVacationRentalsIsTravelWalletCreditPaymentOnly(state),
    isTravelWalletOfferPaymentOnly:
      getVacationRentalsIsTravelWalletOfferPaymentOnly(state),
    selectedHome: getVacationRentalShopSelectedListing(state),
    canRedeemRewards: getAllowRewardsWithPolicy(),
    earnValuesByRewardAcctId: getEarnValuesByRewardAcctId(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    offerToApply: getOfferToApply(state),
  };
};

const mapDispatchToProps = {
  verifyPaymentMethod,
  listPaymentMethods,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchRewardsAccounts,
  fetchProductToEarn,
  fetchAllEarnForProduct,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PaymentCardConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPaymentCard = withRouter(connector(PaymentCard));
