import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { productToEarn } from "../../../../api/v0/rewards/productToEarn";
import { EarnResponse, TravelProductEnum } from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  getCardPaymentAccount,
  getOfferToApply,
  getTotalVacationRentalsCreditCardPaymentRequiredInFiatPrice,
} from "../../reducer";
import Logger from "../../../../utils/logger";
import { getHSPEarnOfferDescription } from "halifax";

export function* fetchProductToEarnSaga() {
  try {
    const state: IStoreState = yield select();
    const paymentTotal =
      getTotalVacationRentalsCreditCardPaymentRequiredInFiatPrice(state);
    const { accountReferenceId } = getCardPaymentAccount(state) || {};

    // If we have not yet selected a credit card, or there is no payment total do nothing.
    if (!accountReferenceId || !paymentTotal?.value) {
      return;
    }

    const offerToApply = getOfferToApply(state);

    const hspEarnOfferDescription = getHSPEarnOfferDescription(offerToApply);

    const result: EarnResponse = yield productToEarn({
      account: accountReferenceId,
      travelProduct: TravelProductEnum.Hotels,
      amountUsd: paymentTotal.value,
      earnOfferDescription: hspEarnOfferDescription,
    });

    yield put(actions.setEarnValue(result.amount));
  } catch (e) {
    Logger.debug(e);
  }
}
