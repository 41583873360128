import { Tooltip, Typography, Box } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import React from "react";

const REVIEWS_TOOLTIP_CONTENT =
  "This is an external customer review score. External customer review scores come from a variety of other trusted travel websites that have similar standards to our platform.";

export const ReviewIconTooltip = () => {
  return (
    <Tooltip
      classes={{
        popper: "pets-info-tooltip-popper",
        tooltip: "pets-info-tooltip-text",
      }}
      enterTouchDelay={0}
      title={
        <Typography variant="subtitle2" className="tooltip-text">
          {REVIEWS_TOOLTIP_CONTENT}
        </Typography>
      }
      placement="bottom"
    >
      <Box marginLeft="4px">
        <Icon className="icon-info-circle" name={IconName.InfoCircle} />
      </Box>
    </Tooltip>
  );
};

export interface IReviewScore {
  score: number | undefined;
  size: "sm" | "lg";
}
export const ReviewScore = (props: IReviewScore) => {
  const { score, size } = props;

  return score ? (
    <Box
      display="flex"
      style={{ backgroundColor: "#F4F4F4" }}
      padding={size == "sm" ? "2px 4px" : "3px 6px"}
      marginLeft={size == "sm" ? "2px" : "4px"}
      borderRadius="4px"
      justifyContent="center"
    >
      <Typography style={{ fontSize: size == "sm" ? "12px" : "14px" }}>
        {(score / 10).toPrecision(2)}
      </Typography>
    </Box>
  ) : null;
};

export const getUserRatingQualifier = (userRating: number): string => {
  let userRatingQualifer = "";

  if (userRating) {
    if (userRating > 98) {
      userRatingQualifer = "Exceptional";
    } else if (userRating > 95) {
      userRatingQualifer = "Superb";
    } else if (userRating > 90) {
      userRatingQualifer = "Excellent";
    } else if (userRating > 85) {
      userRatingQualifer = "Very Good";
    } else if (userRating > 80) {
      userRatingQualifer = "Good";
    } else if (userRating > 75) {
      userRatingQualifer = "Pleasant";
    } else if (userRating > 70) {
      userRatingQualifer = "Satisfactory";
    } else if (userRating > 65) {
      userRatingQualifer = "Fair";
    } else if (userRating > 60) {
      userRatingQualifer = "Adequate";
    } else if (userRating > 55) {
      userRatingQualifer = "Mediocre";
    } else if (userRating > 50) {
      userRatingQualifer = "Below Average";
    } else if (userRating > 45) {
      userRatingQualifer = "Poor";
    } else {
      userRatingQualifer = "Very Poor";
    }
  }

  return userRatingQualifer;
};
